<template>
  <div>
    <!-- lost pet reported in ... modals -->
    <!-- item.lostCityModals &&  -->
    <ModalsConfirm
      v-for="(item, i) in petsaslost"
      :key="i"
      :show="lostCityModals"
    >
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto h-28"
          src="@/assets/images/attention-icon.png"
          alt="attention-icon"
        />
        <h1 class="text-xl">Lost Pet Reported In Berlin!</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          A pet was just reported lost! Please share your location to join the
          community search. If you are within the vicinity, you will receive
          updates and coordinates to where it was last seen.
        </h5>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Dismiss"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="submitDimiss(item)"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Share Location"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitShareLocalion(item)"
      />
    </ModalsConfirm>
    <!-- lost pet reported in ... modals end -->
    <!-- lost pet reported in your area modals -->
    <ModalsConfirm :show="lostAreaModals">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto h-28"
          src="@/assets/images/attention-icon.png"
          alt="attention-icon"
        />
        <h1 class="text-xl">Lost Pet Reported In Your Area!</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          A pet was reported lost 300m from your location. Please help, so that
          the lost soul can be found sooner! Scan the pet to send its location
          to the pet owner.
        </h5>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Dismiss"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="lostAreaModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Join Search"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="joinSearchModals"
      />
    </ModalsConfirm>
    <!-- lost pet reported in your area modals end -->
    <!-- too far away modals -->
    <ModalsConfirm :show="tooFarModals">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto h-28"
          src="@/assets/images/error-icon.png"
          alt="error-icon"
        />
        <h1 class="text-xl">Too far away to join the search party!</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          Unfortunately, you are more than 1km away from the lost pet’s last
          sighting. You can still help the owner recover the lost pet, in case
          you scan the pet at a later time.
        </h5>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Dismiss"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="tooFarModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Lost Pet Profile"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitLostPetProfile"
      />
    </ModalsConfirm>
    <!-- too far away modals end -->
    <!-- joined search party modals -->
    <ModalsConfirm :show="joinedPartyModals">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto h-28"
          src="@/assets/images/success-icon.png"
          alt="error-icon"
        />
        <h1 class="text-xl">You have joined the search party!</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          You will receive notification updates about the lost pet for one hour.
          You can access their profile directly at any time by clicking on the
          notification updates.
        </h5>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Dismiss"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="joinedPartyModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Lost Pet Profile"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitLostPetProfile"
      />
    </ModalsConfirm>
    <!-- joined search party modals end -->
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import { petRecordById } from "../../../services/petRecordService";
import {
  infoReportedPetAsLost,
  shareLocation,
  joinSearch,
  getPosthogEvent,
  deletePosthogEvent,
} from "../../../services/panicButtonService";
//import posthog from "posthog-js";
import Parse from "parse";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      withinArea: false,
      lostCityModals: true,
      lostAreaModals: false,
      tooFarModals: false,
      joinedPartyModals: false,
      petLostID: "",
      panicLostID: "",
      distance: 100,
      coordinates: {
        lng: 3.05997,
        lat: 36.7762,
      },
      docId: "",
      modalsLostPet: [],
      itemLost: {},
    };
  },
  computed: {
    ...mapGetters({
      petsaslost: "panicbutton/getPetsaslost",
    }),
  },

  async created() {
    // TODO:   get pet as lost to review
    /* this.getPetsaslost(); */
    // console.log("gggg", this.$store.state.panicbutton.petsaslost);
    /*  const listoflost = [];
    this.$store.state.panicbutton.petsaslost.forEach((a) => {
      const lost_info = a.lost_info;
      const usersToNotify = a.usersToNotify;
      const pet_info = a.pet_info;
      lost_info.notification_status == false
        ? usersToNotify.map((c) => cpnsole.log(c))
        : null;
    });
    console.log("rrrr", listoflost); */
    /*  if (userExist != -1) {
      const results = this.$store.state.panicbutton.petsaslost.filter(

        (r) => r.lost_info.notification_status == false
      );
      this.modalsLostPet.push(results);
    } */
    /* location_lat: result.location_lat,
                location_long: result.location_long,
                user_to_notify: userN.email,
                pet: pet,
                notification_status: result.notification_status,
                id_pet: result.id, */
    /*  await getPosthogEvent().then((response) => {
      const endResult = response.data.results;

      const results = endResult.filter(
        (r) =>
          r.properties.user_to_notify == localStorage.getItem("email") &&
          r.properties.notification_status == true
      );
      let results2 = [];
      results.map((i) => {
        const a = endResult.filter(
          (r) =>
            r.distinct_id == i.distinct_id &&
            //r.properties.user_to_notify == localStorage.getItem("email") &&
            r.properties.notification_status == false
        );
        results2 = results2.concat(a);
      });

      let finalResult = [];

      if (results2.length !== 0) {
        results2.map((t) => {
          results.map((v) => {
            if (t.distinct_id != v.distinct_id) finalResult.push(v);
          });
        });
      } else {
        finalResult = results;
      }

      for (let i = 0; i < finalResult.length; i++) {
        const object = finalResult[i].properties;
        this.lostCityModals = true;
        this.modalsLostPet.push({
          id: finalResult[i].id,
          lostCityModals: true,
          panicLostID: object.id_pet,
          petLostID: object.pet,
        });

        //alert(object.distinct_id + " - " + object.user_to_notify);
      }
    }); */
    /*    const ReportLostPet = Parse.Object.extend("reportLostPet");
    const query = new Parse.Query(ReportLostPet);
    query.equalTo("user_to_notify", localStorage.getItem("email"));
    query.equalTo("notification_status", true);

    const results = await query.find();

    for (let i = 0; i < results.length; i++) {
      const object = results[i];
      this.panicLostID = object.get("id_pet");
      this.petLostID = object.get("pet");

      console.log("id panic button : ", this.panicLostID);
      console.log("pet : ", this.petLostID);
      //alert(object.id + " - " + object.get("user_to_notify"));
    }*/
    /*  if (this.panicLostID) {
      const { data: result } = await infoReportedPetAsLost(this.panicLostID);
      console.log("----------", result);
      this.$getLocation({}).then((coordinates) => {
        this.coordinates = coordinates;
      });
      this.lostCityModals = true;
    } */
  },
  methods: {
    ...mapActions({
      getPetsaslost: "panicbutton/getPetsaslost",
    }),
    async submitShareLocalion(item) {
      const req = {
        location_long: this.coordinates.lng,
        location_lat: this.coordinates.lat,
      };

      const { data: distance } = await shareLocation(item.lost_id, req);

      this.distance = parseFloat(distance.distance_in_km);

      this.lostCityModals = false;
      this.distance < 1
        ? (this.lostAreaModals = false)
        : (this.tooFarModals = true);

      //console.log("---------*************delete classe from Parse");
      //---------- delete reportLostPet
      /* const ReportLostPet = Parse.Object.extend("reportLostPet");
      const query = new Parse.Query(ReportLostPet);
      query.equalTo("user_to_notify", localStorage.getItem("email"));
      query.equalTo("notification_status", true);
      const results = await query.find(); */
      //alert("Successfully retrieved " + results.length + " scores.");
      // Do something with the returned Parse.Object values
      /* for (let i = 0; i < results.length; i++) {
        const object = results[i];
        object.destroy().then(
          (myObject) => {
            console.log("The object was deleted from the Parse Cloud.");
          },
          (error) => {
            console.log("The delete failed.", error);
            // error is a Parse.Error with an error code and message.
          }
        );
      } */
      this.lostCityModals = false;
      this.itemLost = item;
    },

    async submitDimiss(item) {
      //delete posthog object

      //result.id + userN.email + pet

      /*   const distinct_id =
        item.panicLostID + localStorage.getItem("email") + item.petLostID;

      posthog.capture("EventLostPet", {
        distinct_id: distinct_id,
        notification_status: false,
      }); */
      this.lostCityModals = false;
    },

    async joinSearchModals() {
      try {
        await joinSearch(
          "26912bf8-680b-4bd7-b00c-08865a4f623b",
          localStorage.getItem("email")
        );

        this.lostAreaModals = false;
        this.joinedPartyModals = true;
      } catch (error) {
        console.log("error");
      }
    },
    async submitLostPetProfile() {
      /*   const { data: result } = await infoReportedPetAsLost(this.itemLost.panicLostID,this.itemLost.petLostID);
        console.log("----------", result);
        this.$getLocation({}).then((coordinates) => {
          this.coordinates = coordinates;
        });
        this.lostCityModals = true; */

      await infoReportedPetAsLost(
        this.itemLost.lost_id,
        this.itemLost.petID
      ).then((result) => {
        const petRecord = result.data;
        this.lostAreaModals = false;

        this.goTo("LostModeProfile", petRecord);
      });
    },

    goTo(page, pet) {
      localStorage.setItem("petLost", JSON.stringify(pet));

      this.$router.push({
        name: page,
        params: { data: pet },
      });
    },
  },
};
</script>

<style></style>
