<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11.5" cy="11.5" r="11.5" :fill="bgColor" />
    <path
      d="M9.37468 5.83317L14.333 11.4998L9.37467 17.1665"
      :stroke="iconColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 23,
    },
    height: {
      type: [Number, String],
      default: 23,
    },
    bgColor: {
      type: String,
      default: "#ACE6E1",
    },
    iconColor: {
      type: String,
      default: "#09A89D",
    },
  },
};
</script>