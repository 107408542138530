<template>
  <div class="w-full flex justify-between items-center">
    <ul class="flex items-center font-proximaMedium">
      <li
        @click="clickTab(1)"
        class="
          text-xs
          md:text-base
          bg-white
          pr-8
          pl-4
          md:pr-14 md:pl-8
          py-3
          md:py-2
          rounded-full
          cursor-pointer
        "
        :class="{ 'menuButton paddingR': whichTab === 1}"
      >
        {{ title1 }}
      </li>
      <li
        @click="clickTab(2)"
        class="
          text-xs
          md:text-base
          bg-white
          -ml-6
          md:-ml-12
          pl-8
          pr-4
          md:pl-14 md:pr-8
          py-3
          md:py-2
          rounded-full
          cursor-pointer
        "
        :class="{ 'menuButton paddingL': whichTab === 2 }"
      >
        Friends' Pets
      </li>
      <li>
        <img
          v-if="showDeceased"
          @click="clickTab(3)"
          class="cursor-pointer"
          :class="{ 'border-b-4 border-primary': whichTab === 3 }"
          src="@/assets/images/rainbow-icon.png"
          alt="rainbow-icon"
        />
      </li>
    </ul>
    <HeaderSearch />
  </div>
</template>

<script>
import HeaderSearch from "./HeaderSearch.vue"
export default {
  props: {
    title1: {
      type: [String],
      default: "Your Pet Records",
    },
    title2: {
      type: [String],
      default: "Friend's Pets",
    },
    showDeceased: {
      type: [Boolean],
      default: true,
    },
  },
  components: {
    HeaderSearch
  },
  data() {
    return {
      whichTab: 1,
      showSearch: false,
    };
  },
  methods: {
    clickTab(i) {
      this.whichTab = i;
      this.$store.commit("PetoverviewTab", i);
    },
    routeDeceased() {
      this.$router.push({
        name: "PetDeceased"
      });
    },
  },
};
</script>

<style scoped>
.menuButton {
  color: #fff;
  background: linear-gradient(270deg, #09a89d 1.41%, #0ac0cb 23.78%);
  z-index: 2;
}
.paddingL {
  padding-left: 32px;
}
.paddingR {
  padding-right: 32px;
}
@media only screen and (max-width: 600px) {
  .paddingL {
    padding-left: 16px;
  }
  .paddingR {
    padding-right: 16px;
  }
}
</style>