<template>
  <div>
    <!-- medical emergency modals -->
    <ModalsConfirm :show="medicalEmergencyModals" style="height: 20px">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto"
          src="@/assets/images/Distressed1.png"
          alt="Distressed1"
        />
        <h1 class="text-2xl">Medical Emergency</h1>
      </div>
      <div slot="actionText">
        <h5 class="text-center font-proximaLight text-sm">
          You are about to trigger an automated process which will lead to the
          following:
        </h5>
        <ul class="mt-4 list-disc text-left font-proximaLight text-sm">
          <li>Phone the nearest open vet clinic</li>
          <li>
            Send an email with up to 10 photos or 30 seconds of video attached
            (this media will also be used to identify your injured pet).
          </li>
          <li>Share your pet’s full medical history</li>
          <li>Your current location (coordinates) will also be shared.</li>
          <li>Maps will open to the clinic’s address</li>
        </ul>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="medicalEmergencyModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-red"
        paddingX="px-8"
        width="w-full"
        @click.native="openContactVetModals"
      />
    </ModalsConfirm>
    <!-- medical emergency modals end -->
    <!-- contact with vet modals -->
    <ModalsConfirm :show="contactVetModals">
      <div slot="actionQuestion" class="relative">
        <h1 class="text-xl">You were in contact with:</h1>
      </div>
      <div slot="actionText">
        <div class="mt-6 flex items-start">
          <img
            class="m-auto h-32"
            src="@/assets/images/vet-icon.png"
            alt="vet-icon"
          />
          <h5 class="text-left font-proximaLight text-xl">
            {{ closervet.name }}<br /><br />
            {{ closervet.address }}
          </h5>
        </div>
        <SubmitButton
          class="md:ml-1"
          title="Save & Continue"
          background="bg-success"
          paddingX="px-8"
          width="w-full"
          @click.native="openIdentifyModals"
        />
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- contact with vet modals end -->
    <!-- identify injured pets modals -->
    <ModalsConfirm
      :show="identifyInjuredPets"
      @close="identifyInjuredPets = false"
    >
      <div slot="actionQuestion">
        <div class="w-max m-auto rounded-full p-6 bg-primary-20">
          <MedicalHistIcon width="82px" height="82px" />
        </div>
        <h1 class="mt-4 text-2xl">Identify Injured Pet(s)</h1>
      </div>
      <div
        slot="actionText"
        class="
          flex flex-col
          items-center
          justify-center
          text-sm
          font-proximaLight
        "
      >
        <span class="my-2">Select at least one pet </span>
        <div class="h-52 w-full md:w-9/12 overflow-scroll overflow-x-hidden">
          <FormError :errors="errors.pet" />
          <div
            class="my-2 flex items-center justify-between"
            v-for="(contact, i) in this.contacts"
            :key="i"
          >
            <div class="flex items-center">
              <img
                class="h-6"
                :src="petPicture(contact)"
                alt="profilepic-icon"
              />
              <span class="ml-2">{{ contact.callName }}</span>
            </div>
            <RadioOnoffBorder
              :checked="contact.checked"
              :val="contact"
              @inputVal="inputVal"
            />
          </div>
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="identifyInjuredPets = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="openPhotoModals"
      />
    </ModalsConfirm>
    <!-- identify injured pets modals end -->
    <!-- question photo modals -->
    <ModalsConfirm :show="questionPhotoModals">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto"
          src="@/assets/images/camera-icon.png"
          alt="camera-icon"
        />
        <h1 class="text-xl mt-4">Take Photo of Pet(s)</h1>
      </div>
      <div slot="actionText">
        <span v-if="imagesList.length == 0"
          >You may take up to 10 photos of the injuries.</span
        >
        <span v-else
          >You have taken {{ imagesList.length }} photos, you may take
          {{ 10 - imagesList.length }} more.</span
        >
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Skip"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="skipPhoto"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Ready"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="openTakePhotoModals"
      />
    </ModalsConfirm>
    <!-- question photo modals end -->
    <!-- take photo modals -->
    <ModalsConfirm :show="takePhotoModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="mb-2 font-proximaMedium text-lg underline"
          @click="addNewPhoto"
        >
          Open Camera
        </div>
        <img :src="newPhoto" :alt="newPhoto" />
      </div>
      <div slot="actionText">
        <!-- <span>You may take up to 10 photos of the injuries.</span> -->
        <SubmitButton
          class=""
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full"
          @click.native="confirmPhoto"
        />
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
      <!-- <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Retake"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="retakePhoto"
      /> -->
      <!-- <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Confirm"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="confirmPhoto"
      /> -->
    </ModalsConfirm>
    <!-- take photo modals end -->
    <!-- question video modals -->
    <ModalsConfirm :show="questionVideoModals">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto"
          src="@/assets/images/videocam-icon.png"
          alt="camera-icon"
        />
        <h1 class="text-xl mt-4">Take Video of Pet(s)</h1>
      </div>
      <div slot="actionText">
        <span>You can take 30 seconds of video of your pet.</span>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        title="Skip"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="skipVideo"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Ready"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="openTakeVideoModals"
      />
    </ModalsConfirm>
    <!-- question video modals end -->
    <!-- take photo modals -->
    <ModalsConfirm :show="takeVideoModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="mb-2 font-proximaMedium text-lg underline"
          @click="addNewVideo"
        >
          Open Camera
        </div>
        <video v-if="newVideo" class="mx-auto" width="400" controls autoplay>
          <source :src="newVideo" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div slot="actionText">
        <!-- <span>You may take up to 10 photos of the injuries.</span> -->
        <SubmitButton
          class=""
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full"
          @click.native="confirmVideo"
        />
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- take photo modals end -->
    <!-- success modals -->
    <ModalsConfirm :show="successModals">
      <div slot="actionQuestion" class="relative">
        <img
          class="m-auto h-28"
          src="@/assets/images/success-icon.png"
          alt="success-icon"
        />
        <h1 class="mt-4 text-xl">Emergency Preparations Complete</h1>
      </div>
      <div slot="actionText">
        <span
          >The full medical history of the selected pets has been shared with
          [clinic]. Your location and the recorded media was also sent via
          email.
        </span>
        <SubmitButton
          class=""
          title="See Clinic Location in Maps"
          background="bg-success"
          paddingX="px-8"
          width="w-full"
          @click.native="seeClinicLocation"
        />
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- success modals end -->
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import FormError from "../../warning/FormError.vue";

import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import MedicalHistIcon from "../../icons/MedicalhistIcon.vue";
import { petPicture } from "../../../methods/petMethods";

import {
  reportInjuredPet,
  addGalleryVedio,
  addGalleryPicture,
  sendEmailinjuredPet,
  closerEmergencyVet,
} from "../../../services/panicButtonService";

export default {
  props: ["conntacts"],
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    RadioOnoffBorder,
    MedicalHistIcon,
    FormError,
  },
  data() {
    return {
      medicalEmergencyModals: false,
      contactVetModals: false,
      identifyInjuredPets: false,
      questionPhotoModals: false,
      takePhotoModals: false,
      questionVideoModals: false,
      takeVideoModals: false,
      successModals: false,
      newPhoto: "",
      imagesList: [],
      newVideo: "",
      videoList: [],
      componentKey: 0,
      pets: [],
      closervet: {},
      errors: {},
      coordinates: {
        lng: 3.05997,
        lat: 36.7762,
      },

      contacts: this.conntacts,
      /*   contacts: [
        {
          id: "1",
          name: "Pet 1",
          checked: false,
        },
        {
          id: "2",
          name: "Pet 2",
          checked: false,
        },
      
      ], */
    };
  },
  created() {
    this.$getLocation({}).then((coordinates) => {
      this.coordinates = coordinates;
    });
  },
  methods: {
    petPicture(pet) {
      return petPicture(pet);
    },
    inputVal(contact, checked) {
      contact.checked = checked;

      const contacts = [...this.contacts];
      const index = contacts.indexOf(contact);
      contacts[index] = { ...contact };
      this.contacts = contacts;
    },

    checkAreaModals(e) {
      this.medicalEmergencyModals = e;
    },
    async openContactVetModals() {
      //---------------------------- call api
      const { data: closervet } = await closerEmergencyVet(
        13.556097,
        52.507587
      );
      this.medicalEmergencyModals = false;
      this.closervet = closervet.closer_vet;

      this.contactVetModals = true;
    },
    openIdentifyModals() {
      this.errors = {};
      this.contactVetModals = false;
      this.identifyInjuredPets = true;
    },
    openPhotoModals() {
      const vals = this.contacts.filter((p) => p.checked == true);

      if (vals.length !== 0) {
        vals.map((v) => this.pets.push(v.petID));

        this.identifyInjuredPets = false;
        this.questionPhotoModals = true;
      } else {
        this.errors.pet = "You must select a pet.";
        console.log(this.errors.pet);
      }
    },
    openTakePhotoModals() {
      this.questionPhotoModals = false;
      this.takePhotoModals = true;
    },
    addNewPhoto() {
      const input = document.createElement("input");
      input.type = "file";
      input.name = "file[]";
      input.accept = "image/*";
      input.capture = "camera";

      input.onchange = (e) => {
        let file = URL.createObjectURL(e.target.files[0]); //e.target.files[0]; //
        this.newPhoto = file;
        this.imagesList.push(e.target.files[0]);
        this.componentKey += 1;
      };

      input.click();
    },
    confirmPhoto() {
      if (this.imagesList.length < 10) {
        this.takePhotoModals = false;
        this.questionPhotoModals = true;
        this.newPhoto = "";
      } else this.skipPhoto();
    },
    skipPhoto() {
      this.questionPhotoModals = false;
      this.questionVideoModals = true;
    },
    openTakeVideoModals() {
      this.questionVideoModals = false;
      this.takeVideoModals = true;
    },
    addNewVideo() {
      const input = document.createElement("input");
      input.type = "file";
      input.name = "file[]";
      input.accept = "video/*";
      input.capture = "camera";

      input.onchange = (e) => {
        let file = URL.createObjectURL(e.target.files[0]);
        this.newVideo = file;
        this.videoList.push(e.target.files[0]);
        this.componentKey += 1;
      };

      input.click();
    },
    confirmVideo() {
      if (this.videoList.length < 1) {
        this.takeVideoModals = false;
        this.questionVideoModals = true;
        this.newVideo = "";
      } else this.skipVideo();
    },
    skipVideo() {
      console.log("--------------********************************");
      this.questionVideoModals = false;
      this.successModals = true;
    },

    async seeClinicLocation() {
      this.takeVideoModals = false;
      ///-------------------------------
      /*  console.log("contacts", this.contacts);
      console.log("pets", this.pets);
      console.log("imagesList : ", this.imagesList);
      console.log("videoList", this.videoList);*/

      try {
        const data = {
          pets: this.pets,
          userEmailAddress: localStorage.getItem("email"),
          location_long: this.coordinates.lng,
          location_lat: this.coordinates.lat,
          vetEmailAddress: "technical@pawapeau.com",//"testing@perpetual.care", //this.closervet.emailAddress
        };
        console.log(data);

        const { data: injuredPet } = await reportInjuredPet(data);
        console.log(injuredPet.id);

        this.successModals = false;

        const id = injuredPet.id;
        if (this.videoList.length !== 0)
          await addGalleryVedio(id, this.videoList[0]);

        this.imagesList.map(async (pg) => {
          await addGalleryPicture(id, pg);
        });

        await sendEmailinjuredPet(id);
      } catch (error) {
        this.successModals = false;
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>