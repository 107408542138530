<template>
  <div class="w-auto lg:w-3/12">
    <div class="hidden md:block w-full bg-white px-3 py-2 rounded-full relative">
      <img
        class="h-3 absolute top-4 left-4"
        src="@/assets/images/search-icon.png"
        alt="search-icon"
      />
      <input
        class="w-full pl-5 py-1 bg-lightBlue rounded-full outline"
        type="text"
        placeholder="Search"
      />
    </div>
    <div class="block md:hidden relative">
      <div
        @click="showSearch = !showSearch"
        class="bg-white rounded-full cursor-pointer"
      >
        <img
          class="py-2 px-6"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
      <div
        v-if="showSearch"
        class="bg-white p-3 rounded-full absolute top-10 -left-28"
      >
        <input
          class="px-5 py-1 bg-lightBlue rounded-full"
          type="text"
          placeholder="Search"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSearch: false,
    };
  },
};
</script>

<style scoped>
input,
input::placeholder {
  font-size: 12px;
}
</style>